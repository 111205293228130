





























































































































































































import {
  PropType,
  computed,
  defineComponent,
  ref,
  toRef,
} from '@nuxtjs/composition-api';
import { SfButton, SfLink, SfPrice } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';
import CriticScoreSummary from '~/bbrTheme/modules/catalog/product/components/product-types/CriticScoreSummary.vue';
import {
  ProductReview,
  ConfigurableVariant,
  ProductInterface,
  SimpleProduct,
  ProductLabel,
} from '~/modules/GraphQL/types';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import Maturity from '~/bbrTheme/components/atoms/Maturity.vue';
import AddToCart from '~/bbrTheme/modules/catalog/category/components/views/list/AddToCart.vue';
import MessageBlock from '~/bbrTheme/modules/catalog/components/MessageBlock.vue';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { useCart } from '~/modules/checkout/composables/useCart';
import {
  getAttributeValue,
  getFamilyType,
  getCasesQtyLabel,
  isBbrVariantType,
  isBbxVariantType,
  getProductUid,
  getListingType,
  getListingId,
  getLabels,
} from '~/bbrTheme/modules/catalog/getters/productGetters';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import { useCustomPrice, useVariantBottleInfo } from '~/bbrTheme/composables';
import { useBuyingOption } from '~/bbrTheme/modules/catalog/stores/buyingOption';

interface ListingItem {
  product: SimpleProduct;
  variant: ConfigurableVariant;
  bottleInfo: string;
  labels: ProductLabel[];
  price: number;
  specialPrice: number;
  casesQtyLabel: string;
  isBBR: boolean;
  isBBX: boolean;
}

export default defineComponent({
  name: 'BBRProductCardHorizontal',
  components: {
    SfButton,
    SfLink,
    SfPrice,
    CategoryProductPrice,
    CriticScoreSummary,
    Maturity,
    AddToCart,
    MessageBlock,
  },
  props: {
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    visibleConfigurations: {
      type: Number,
      default: 3,
    },
    visibleLabels: {
      type: Number,
      default: 3,
    },
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    customPrice: {
      type: [Number, String],
      default: null,
    },
    customSpecialPrice: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ['notify:add-to-cart'],
  setup(props) {
    const product = toRef(props, 'product');
    const productPrice = computed(() => getPrice(product.value));
    const familyType = computed(() => getFamilyType(product.value));
    const quantity = ref(props.qty);
    const reviews = computed<ProductReview[]>(() => product.value.reviews.items ?? []);
    const variants = computed<ConfigurableVariant[]>(() => product.value?.variants ?? []);
    const hasReviews = computed(() => reviews.value.length > 0);
    const hasVariants = computed(() => variants.value.length > 0);
    const hasVariantsMoreThanVisible = computed(() => variants.value.length > props.visibleConfigurations);
    const buyingOptionStore = useBuyingOption();
    const { isInBondSelected } = storeToRefs(buyingOptionStore);
    const { canAddToCart } = useCart();
    const { hasCustomPrice, getCustomPrice, getCustomSpecialPrice } = useCustomPrice(product.value);

    // Check if product has at least one BBR item
    const hasBbrItem = computed(() => {
      return variants.value.some((variant) => isBbrVariantType(variant.product));
    });

    // Check if product has at least one BBX item
    const hasBbxItem = computed(() => {
      return variants.value.some((variant) => isBbxVariantType(variant.product));
    });

    // Message block is shown only if product has both BBR and BBX items and family type is Wines
    const hasMessageBlock = computed(() => {
      return familyType.value === FamilyType.Wines && hasBbrItem.value && hasBbxItem.value;
    });

    // Validate quantity value and convert it to number
    const itemQuantity = computed<number>({
      get() {
        return typeof quantity.value === 'string'
          ? Number(quantity.value)
          : quantity.value;
      },
      set(newValue: string | number) {
        quantity.value = newValue;
      },
    });

    // Update quantity value on input change
    const updateItemQuantity = ({ quantity }): void => {
      itemQuantity.value = quantity;
    };

    // Check if configuration is available and toggle it
    const showAllVariants = ref<boolean>(false);

    const toggleConfiguration = (): void => {
      showAllVariants.value = !showAllVariants.value;
    };

    const getUniqueKey = (product: ProductInterface): string => (
      `${getProductUid(product)}_${getListingType(product)}_${getListingId(product)}`
    );

    const listings = computed<ListingItem[]>(() => (
      variants.value.map((variant: ConfigurableVariant) => {
        const product = variant?.product;
        const { listBottleInfo: getBottleInfo } = useVariantBottleInfo(product);
        const { hasCustomPrice, getCustomPrice, getCustomSpecialPrice } = useCustomPrice(product);

        return {
          product,
          variant,
          bottleInfo: getBottleInfo(),
          labels: getLabels(product),
          ...(hasCustomPrice() ? {
            price: getCustomPrice(),
            specialPrice: getCustomSpecialPrice(),
          } : {
            price: getPrice(product).regular,
            specialPrice: getPrice(product).special,
          }),
          casesQtyLabel: isInBondSelected.value ? getCasesQtyLabel(product) : '',
          isBBR: isBbrVariantType(product),
          isBBX: isBbxVariantType(product),
        };
      })
    ));

    const messageBlock = {
      hasMessageBlock,
      hasBbrItem,
      hasBbxItem,
    };

    return {
      getUniqueKey,
      hasCustomPrice,
      getCustomPrice,
      getCustomSpecialPrice,
      familyType,
      productPrice,
      reviews,
      hasReviews,
      listings,
      itemQuantity,
      updateItemQuantity,
      canAddToCart,
      showAllVariants,
      toggleConfiguration,
      isInBondSelected,
      hasVariants,
      hasVariantsMoreThanVisible,
      getAttributeValue,
      ...messageBlock,
    };
  },
});
