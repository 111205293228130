









































import {
  PropType,
  computed,
  defineComponent,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import BBRProductCardHorizontal from '~/bbrTheme/modules/catalog/components/organisms/BBRProductCardHorizontal.vue';
import { useCart, useImage } from '~/composables';
import {
  useProductsWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import type { Product } from '~/modules/catalog/product/types';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  components: {
    SkeletonLoader,
    BBRProductCardHorizontal,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click:wishlist', 'click:add-to-cart', 'notify:add-to-cart'],
  setup(props) {
    const context = useContext();

    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);
    const { loading: isCartLoading } = useCart();

    const productsFormatted = computed(() => productsWithCommonProductCardProps.value.map((product) => {
      const label = product.commonProps.isInWishlist
        ? 'Remove from Wishlist'
        : 'Save for later';

      return {
        ...product,
        wishlistMessage: context.i18n.t(label),
      };
    }));

    const { imageSizes: { productCardHorizontal: imageSize } } = useImage();

    const { isAuthenticated } = useUser();

    return {
      productsFormatted,
      imageSize,
      isAuthenticated,
      isCartLoading,
    };
  },
});
