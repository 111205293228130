





























import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { SfAddToCart, SfButton, SfQuantitySelector } from '@storefront-ui/vue';
import { ConfigurableVariant, useCart } from '~/composables';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { getSellByCaseOnly } from '~/bbrTheme/modules/catalog/getters/productGetters';
import { getVariantMaxQty } from '~/bbrTheme/modules/catalog/getters/addToCartGetters';
import { useBuyingOption } from '~/bbrTheme/modules/catalog/stores/buyingOption';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ListAddToCart',
  components: {
    SfAddToCart,
    SfButton,
    SfQuantitySelector,
  },
  props: {
    variant: {
      type: [Object, null] as PropType<ConfigurableVariant | null>,
      default: null,
    },
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    }
  },
  emits: ['input', 'afterAddToCart'],
  setup(props, { emit }) {
    const qty = ref<number>(1);
    const {
      cart,
      addItem,
      error,
      loading,
    } = useCart();

    const buyingOptionStore = useBuyingOption();
    const { isInBondSelected } = storeToRefs(buyingOptionStore);

    const addToCartError = computed(() => error.value?.addItem?.message);

    const maxInputQty = computed(() => {
      const product = props.variant?.product || props.product;

      if (!product) return 0;

      return getVariantMaxQty(
        cart.value,
        product,
        isInBondSelected.value || getSellByCaseOnly(product),
      );
    });

    const addToCart = async () => {
      const sellByCaseOnly = getSellByCaseOnly(props.variant?.product);

      await addItem({
        product: props.product,
        quantity: qty.value || 1,
        productConfiguration: {
          isBottle: !sellByCaseOnly && !isInBondSelected.value,
          variants: props.variant ? [props.variant] : [],
        },
      });

      emit('afterAddToCart', {
        isAdded: !addToCartError.value,
        isCase: sellByCaseOnly || isInBondSelected.value,
        product: props.product,
        quantity: qty.value || 1,
        variant: props.variant?.product,
      });
    };

    const isValid = computed(() => {
      const product = props.variant?.product || props.product;
      const maxQty = getVariantMaxQty(
        cart.value,
        product,
        isInBondSelected.value || getSellByCaseOnly(product),
      );

      return maxQty >= 1;
    });

    const onQtyChange = ({ quantity }) => {
      qty.value = quantity;
      emit('input', quantity)
    };

    return {
      addToCart,
      maxInputQty,
      onQtyChange,
      isValid,
      qty,
      loading,
    };
  },
});
